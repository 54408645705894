<template>
  <div class="not-found-container">
    <img src="../assets/404.jpg" alt="" />
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>
 
<style lang = "less" scoped>
.not-found-container {
  background: #90cbc0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>