<template>
  <img
    :src="url"
    class="avatar-container"
    :style="{
      width: size + 'px',
      height: size + 'px',
    }"
  />
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      required: true,
    },
    size: {
      type: Number,
      default: 150,
    },
  },
};
</script>

<style scoped>
.avatar-container {
  border-radius: 50%;
  display: block;
  object-fit: cover;
}
</style>