// import './mock'
import Vue from 'vue'
import App from './App.vue'
import './styles/global.less'
import router from './router'
// Vue.config.productionTip = false
import { showMessage } from '@/utils'
import vLoading from '@/directives/loading'
import vLazy from '@/directives/lazy'
import store from './store'
import './eventBus'
store.dispatch('setting/fetchSetting')

Vue.prototype.$showMessage = showMessage

Vue.directive('loading', vLoading)
Vue.directive('lazy', vLazy)

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')

