import { getSetting } from '@/api/setting'
import { titleController } from '@/utils'

export default {
    namespaced: true,
    state: {
        isLoading: false,
        data: null
    },
    mutations: {
        setLoading(state, payload) {
            state.isLoading = payload
        },
        setData(state, payload) {
            state.data = payload
        }
    },
    actions: {
        // <link rel="shortcut icon " type="images/x-icon" href="./favicon.ico">
        async fetchSetting(ctx) {
            ctx.commit('setLoading', true)
            const res = await getSetting()
            res.avatar = res.avatar
            res.qqQrCode = res.qqQrCode
            res.weixinQrCode = res.weixinQrCode
            ctx.commit('setData', res)
            ctx.commit('setLoading', false)
            if (res.favicon) {
                let link = document.querySelector("link[rel='shortcut icon']")
                if (link) {
                    return
                }
                link = document.createElement('link')
                link.rel = "shortcut icon"
                link.type = "images/x-icon"
                link.href = res.favicon
                document.querySelector('head').appendChild(link)
            }
            if (res.siteTitle) {
                titleController.setSiteTitle(res.siteTitle)
            }
        }
    }

}