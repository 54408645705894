import eventBus from '@/eventBus'
import { debounce } from "@/utils"
import defaultGif from '@/assets/default.gif'
let imgs = []

function setImage(img) {
    img.dom.src = defaultGif
    const rect = img.dom.getBoundingClientRect()
    const height = rect.height
    const clientHeight = document.documentElement.clientHeight
    if (rect.top >= -height && rect.top <= clientHeight) {
        // const tempImg = new Image()
        // tempImg.onload = () => {
        //     img.dom.src = tempImg.src
        //     imgs = imgs.filter(it => it !== img)

        // }
        // tempImg.src = img.src;
        img.dom.src = img.src
        imgs = imgs.filter(it => it !== img)
    }
}

function setImages() {
    for (const img of imgs) {
        setImage(img)
    }
}

function handleScroll() {
    setImages()
}

eventBus.$on('mainScroll', debounce(handleScroll, 50))

export default {
    inserted(el, bindings) {
        const img = {
            dom: el,
            src: bindings.value
        }
        imgs.push(img)
        setImage(img)
    },
    unbind(el) {
        imgs = imgs.filter((it) => it.dom !== el)
    }
}