<template>
  <nav class="menu-container">
    <router-link
      :exact="item.exact"
      :to="item.link"
      v-for="item in items"
      :key="item.link"
      active-class="selected"
      exact-active-class=""
    >
      <div class="icon">
        <Icon :type="item.icon" />
      </div>
      <span>{{ item.title }}</span>
    </router-link>
  </nav>
</template>

<script>
import Icon from "@/components/Icon";
export default {
  components: {
    Icon,
  },
  data() {
    return {
      items: [
        {
          link: "/",
          icon: "home",
          title: "首页",
          exact: true,
        },
        {
          link: "/blog",
          icon: "blog",
          title: "文章",
          exact: false,
        },
        {
          link: "/about",
          icon: "about",
          title: "关于我",
          exact: true,
        },
        {
          link: "/project",
          icon: "code",
          title: "项目&效果",
          exact: true,
        },
        {
          link: "/message",
          icon: "chat",
          title: "留言板",
          exact: true,
        },
      ],
    };
  },
  methods: {
    isSelected(item) {
      let link = item.link.toLowerCase();
      let curPathname = location.pathname.toLowerCase();
      if (item.startWith) {
        return curPathname.startsWith(link);
      } else {
        return link === curPathname;
      }
    },
  },
};
</script>
 
<style lang = "less" scoped>
@import "~@/styles/var.less";
.menu-container {
  color: @gray;
  margin: 24px 0;
  a {
    display: block;
    display: flex;
    height: 45px;
    padding: 0 50px;
    align-items: center;
    .icon {
      width: 24px;
    }
    &:hover {
      color: #fff;
    }
    &.selected {
      background-color: darken(@words, 3%);
    }
  }
}
</style>