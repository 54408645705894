import axios from "axios";
import { showMessage } from "../utils";

const instance = axios.create({
    baseURL: ""
})

instance.interceptors.response.use(resp => {
    if (resp.data.code !== 0) {
        showMessage({
            content: resp.data.msg,
            type: "error",
            duration: 1500,
        })
        return null
    }
    return resp.data.data
})

export default instance