<template>
  <div class="app-container">
    <Layout>
      <template #left>
        <div class="aside">
          <SetAside />
        </div>
      </template>
      <template #default>
        <router-view></router-view>
      </template>
    </Layout>
    <ToTop />
  </div>
</template>

<script>
import Layout from "./components/Layout";
import SetAside from "./components/SiteAside";
import ToTop from "./components/ToTop";
export default {
  name: "App",
  components: {
    Layout,
    SetAside,
    ToTop,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="less" scoped>
@import "~@/styles/mixin.less";
.app-container {
  .self-fill(fixed);
}
.aside {
  width: 250px;
  height: 100%;
}
.text {
  width: 500px;
  height: 500px;
  margin: 5px auto;
  border: 1px solid #000;
}
</style>
