import 'nprogress/nprogress.css'
import { start, done, configure } from 'nprogress'
import NotFound from "@/views/NotFound.vue"

configure({
    trickleSpeed: 20,
    showSpinner: false,
})

function delay(duration) {
    return new Promise(resolve => {
        setTimeout(() => {
            resolve()
        }, duration)
    })
}

function getPageComponent(pageCompResolve) {
    return async () => {
        start()
        if (process.env.NODE_ENV === "development") {
            delay(2000)
        }
        const comp = await pageCompResolve()
        done()
        return comp
    }
}

export default [
    {
        path: '/',
        name: 'Home',
        component: getPageComponent(() => import(/* webpackChunkName: "home" */'@/views/Home')),
        meta: {
            title: '首页'
        }
    },
    {
        path: '/blog',
        name: 'Blog',
        component: getPageComponent(() => import('@/views/Blog')),
        meta: {
            title: '文章'
        }
    },
    {
        path: '/blog/cate/:categoryId',
        name: 'CategoryBlog',
        component: getPageComponent(() => import('@/views/Blog')),
        meta: {
            title: '文章'
        }
    },
    {
        path: '/blog/:id',
        name: 'BlogDetail',
        component: getPageComponent(() => import('@/views/Blog/Detail')),
        meta: {
            title: '文章详情'
        }
    },
    {
        path: '/about',
        name: 'About',
        component: getPageComponent(() => import('@/views/About')),
        meta: {
            title: '关于我'
        }
    },
    {
        path: '/project',
        name: 'Project',
        component: getPageComponent(() => import('@/views/Project')),
        meta: {
            title: '项目&效果'
        }
    },
    {
        path: '/message',
        name: 'Message',
        component: getPageComponent(() => import('@/views/Message')),
        meta: {
            title: '留言板'
        }
    },
    {
        path: '*',
        name: 'NotFound',
        component: NotFound
    },
]
