import { getComponentRootDom } from './'
import Icon from '@/components/Icon'
import styles from './showMessage.module.less'


// info  error  success  warn
/**
 * 弹出消息
 * @param {Object} option 
 * @param {String} option.type
 * @param {String} option.content
 * @param {Number} option.duration
 * @param {HTMLElement} option.container
 */

export default function (option = {}) {
    const type = option.type || 'info';
    const content = option.content || '登陆成功';
    const duration = option.duration || 2000;
    const container = option.container || document.body

    // 创建元素
    const div = document.createElement('div')
    const iconDom = getComponentRootDom(Icon, { type })
    div.innerHTML = `<span class=${styles.icon}>${iconDom.outerHTML}</span><div>${content}</div>`

    div.className = `${styles.message} ${styles['message-' + type]}`

    if (option.container) {
        if (getComputedStyle(container).position === "static") {
            container.style.position = 'relative'
        }
    }

    container.appendChild(div)
    container.clientHeight

    div.style.opacity = 1;
    div.style.transform = "translate(-50%,-50%)";

    setTimeout(() => {
        div.style.opacity = 0;
        div.style.transform = "translate(-50%,-50%) translateY(-25px)";
        div.addEventListener('transitionend', () => {
            div.remove()
            option.callback && option.callback()
        }, { once: true })
    }, duration)

}