import Vue from 'vue'
// const listeners = {}

// export default {
//     $on(eventName, handler) {
//         if (!listeners[eventName]) {
//             listeners[eventName] = new Set()
//         }
//         listeners[eventName].add(handler)
//     },
//     $off(eventName, handler) {
//         if (!listeners[eventName]) {
//             return
//         }
//         listeners[eventName].delete(handler)
//     },
//     $emit(eventName, ...arg) {
//         if (!listeners[eventName]) {
//             return
//         }
//         for (const handler of listeners[eventName]) {
//             handler(...arg)
//         }
//     }
// }
/*
 * 事件名：mainScroll
 * 含义：主区域滚动条位置变化后触发
 * 参数：
 * - 滚动的dom元素
 */

/*
 * 事件名:setMainScoll
 * 含义:当需要设置主区域滚动条位置时触发
 * 参数:
 * -滚动高度
 */


const app = new Vue({})
Vue.prototype.$bus = app

export default app


